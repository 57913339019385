import React, { useContext } from "react";
import PropTypes from "prop-types";
import { split } from "ramda";
import { localizedDate, mq } from "utils/helper";
import LocalizedLink from "./localizedLink.js";
import { rhythm, scale } from "utils/typography";
import useTranslations from "components/hooks/useTranslations";
import { LocaleContext } from "context/locale-context";
import { colors } from "style/theme";

function PostHeader({ post, location }) {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const isIncident = post.frontmatter.type === ("incident" || "dataset");
  const isJob = post.frontmatter.category === "about/jobs";
  const linkToCategory =
    post.frontmatter.category || split("/", location.pathname)[2];
  const category = linkToCategory.toUpperCase();

  const tr = useTranslations();

  return (
    <>
      <div
        css={{
          position: "relative",
          zIndex: 2,
          backgroundImage: 'url("/assets/background-gray.svg")',
          paddingBottom: isJob ? rhythm(.5) : rhythm(2),
          direction: isRTL ? "rtl" : "ltr",
          [mq[0]]: {
            padding: `${rhythm(2)} ${rhythm(1.3)}`
          }
        }}
      >
        <div
          css={{
            maxWidth: rhythm(34),
            margin: "5rem auto 0 auto",
            //paddingTop: rhythm(3.45),
            [mq[0]]: {
              paddingTop: 0
            }
          }}
        >
          <h4
            css={{
              marginTop: 0,
              marginBottom: rhythm(1 / 2),
              color: colors.light,
              fontWeight: "normal",
              letterSpacing: "0.23px",
              ...scale(1 / 5)
            }}
          >
            <LocalizedLink
              to={linkToCategory}
              css={{
                color: colors.primary,
                paddingBottom: rhythm(1),
                transition: "0.25s",
                ":hover": {
                  textDecoration: colors.light
                }
              }}
            >
              {tr(category).replace("/", " / ")}
            </LocalizedLink>
          </h4>
          <div
            css={{
              paddingBottom: rhythm(0.6),
              marginBottom: rhythm(0.6),
              borderBottom: `6px solid ${colors.primary}`
            }}
          >
            <h1
              css={{
                color: colors.light,
                marginTop: 0,
                marginBottom: rhythm(0.65),
                [mq[0]]: {
                  ...scale(0.8)
                }
              }}
            >
              {post.frontmatter.title}
            </h1>
            {isIncident && (
              <small
                css={{
                  paddingTop: rhythm(2),
                  fontWeight: "bold",
                  color: colors.light
                }}
              >
                {localizedDate(post.frontmatter.date, "d,m,y", isRTL)}
              </small>
            )}
          </div>
          <p css={{ color: colors.light }}>{post.frontmatter.desc}</p>
        </div>
      </div>
    </>
  );
}

PostHeader.propTypes = {
  post: PropTypes.object
};

export default PostHeader;
