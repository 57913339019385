import React, { useContext, useState, useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import Layout from "components/layout";
import SEO from "components/seo";
import { colors, firstBox } from "style/theme";
import { mq } from "utils/helper";
import { rhythm } from "utils/typography";
import PostHeader from "components/PostHeader";
import PostBody from "components/PostBody";
import JobListing from "components/JobListing";
import useWindowSize from "components/hooks/useWindowSize";
import { LocaleContext } from "context/locale-context";
import useTranslations from "components/hooks/useTranslations";
import TableOfContents from "../components/toc";

function BlogPostTemplate({ data, location }) {
  const siteTitle = data.site?.siteMetadata.title || data.mdx.frontmatter.title;
  const post = data.mdx;
  const isPress = post.frontmatter.type === "press";
  const isMethod = post.frontmatter.title === "Method";
  const isJob = post.frontmatter.category === "about/jobs";
  const [windowWidthSize] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const locale = useContext(LocaleContext);
  const hasTOC = !isMobile && !!post.tableOfContents.items && !isPress

  const isRTL = locale === "ar";
  const tr = useTranslations();

  useEffect(() => {
    windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
  }, [windowWidthSize]);

  useEffect(() => {
    // This is releated to the workaound in gatsby-browser.js file
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={{ backgroundColor: colors.dark }}>
      <img
        src="/assets/about-background.gif"
        css={{
          position: "absolute",
          top: 0,
          left: "30%",
          width: "60%",
          height: "auto",
          zIndex: 0,
          opacity: 0.35
        }}
      />
      <Layout title={siteTitle} css={{ zIndex: 2 }}>
        <SEO title={post.frontmatter.title} />

        <PostHeader post={data.mdx} location={location} />
        <div
          css={{
            display: hasTOC ? "grid" : "block",
            gridTemplateColumns: "25% 50% 25%",
            position: "relative",
            zIndex: 2,
            paddingTop: isJob ? 0 : rhythm(2),
            direction: isRTL ? "rtl" : "ltr",
            [mq[0]]: {
              display: "block",
              paddingTop: 0,
              marginRight: rhythm(1.3),
              marginLeft: rhythm(1.3)
            }
          }}
        >
          {hasTOC && <TableOfContents post={data.mdx} tr={tr} />}
          <div
            css={[
              {
                maxWidth: rhythm(32.25),
                margin: "auto",
                marginBottom: rhythm(4)
              },
              isPress && firstBox
            ]}
          >
            {isJob && <JobListing item={data.mdx} showTitle={false} />}
            <PostBody body={post.body} />
          </div>
          {isMobile || <div></div>}
        </div>
      </Layout>
    </div>
  );
}

BlogPostTemplate.propTypes = {
  data: PropTypes.object
};

export default BlogPostTemplate;
export { TableOfContents };

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      id
      excerpt(pruneLength: 160)
      tableOfContents(maxDepth: 2)
      body
      frontmatter {
        title
        level
        date
        desc
        category
        location
        deadline
        active
      }
      fields {
        slug
        locale
      }
    }
  }
`;
