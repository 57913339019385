import React from "react";
import { designSystem } from "style/theme";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

const PostBody = ({ body }) => (
  <MDXProvider
    components={{
      a: designSystem.a,
      p: designSystem.p,
      h1: designSystem.h1,
      h2: designSystem.h2,
      h3: designSystem.h3,
      h4: designSystem.h4,
      h5: designSystem.h5,
      em: designSystem.em,
      li: designSystem.li,
      ul: designSystem.ul,
      blockquote: designSystem.blockquote
    }}
  >
    <MDXRenderer>{body}</MDXRenderer>
  </MDXProvider>
)

export default PostBody;
