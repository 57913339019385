import React from "react";
import Layout from "components/layout";
import PostBody from "components/PostBody";
import PostHeader from "components/PostHeader";
import LocalizedLink from "components/localizedLink";
import { graphql } from "gatsby";
import { rhythm } from "utils/typography";
import SEO from "components/seo";
import { colors } from "style/theme";
import { jsx } from "@emotion/core";
import { localizedDate, mq } from "utils/helper";

const JobListingProperty = ({ label, value }) => (
  <div
    css={{
      fontWeight: 600,
    }}
  >
    <span
      css={{
        color: colors.primary,
        marginRight: ".3rem"
      }}
    >
      {label}:
    </span>
    <span
      css={{
        color: colors.light
      }}
    >
      {value}
    </span>
  </div>
);

const generateDeadlineText = ({ active, deadline }) => {
  if (!active) {
    return "Not accepting submissions"
  }
  if (!deadline) {
    return null;
  }

  return localizedDate(deadline, "d,m,y", false)
}

const JobListing = ({ item, showTitle = true }) => {
  const { id, fields, frontmatter } = item;
  const { title, location, deadline, active } = frontmatter;

  const deadlineText = generateDeadlineText(frontmatter);

  return (
    <div
      key={id}
      css={{
        marginBottom: "4rem"
      }}
    >
      {showTitle && (
        <h3
          css={{
            marginBottom: "1.2rem"
          }}
        >
          <LocalizedLink
            to={fields.slug}
            css={{
              color: colors.light,
              textDecoration: "underline"
            }}
          >
            {title}
          </LocalizedLink>
        </h3>
      )}
      {location && <JobListingProperty label={"Location"} value={location} />}
      {deadlineText && <JobListingProperty label={"Deadline"} value={deadlineText} />}
    </div>
  )
}

export default JobListing;
