import React, { useContext } from "react";
import { toLower } from "ramda";
import { LocaleContext } from "context/locale-context";
import { mq } from "utils/helper";
import { rhythm } from "utils/typography";
import { colors } from "style/theme";
import useTranslations from "components/hooks/useTranslations";

function TableOfContents({ post }) {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  const toc = post.tableOfContents.items;
  const isPress = toLower(post.frontmatter.title) === "press";
  const headOfToc = "Table Of Contents";
  if (toc === undefined || isPress) {
    return <div></div>;
  } else {
    return (
      <div
        css={{
          [mq[1]]: {
            display: "none"
          }
        }}
      >
        <ul
          css={{
            position: "sticky",
            top: rhythm(6),
            left: isRTL ? "auto" : rhythm(2),
            right: isRTL ? rhythm(3) : "auto",
            maxWidth: rhythm(8),
            marginBottom: rhythm(5),
            maxHeight: "calc(-10rem + 100vh);",
            overflowY: "auto",
            listStyle: "none",
            direction: isRTL ? "rtl" : "ltr"
          }}
        >
          <h4
            css={{
              color: colors.light,
              margin: 0,
              marginBottom: rhythm(1),
              paddingTop: "1rem"
            }}
          >
            {tr(headOfToc)}
          </h4>
          {toc.map(item => {
            return (
              <li
                key={item.url}
                css={{
                  color: colors.light,
                  borderBottom: "1px solid #CACACA",
                  padding: "0.5rem"
                }}
              >
                <a
                  href={item.url}
                  css={{
                    color: colors.light,
                    transition: "0.25s",
                    ":hover, :active": {
                      color: colors.primary
                    }
                  }}
                >
                  {item.title}
                </a>
                {item.items &&
                  item.items.map(item => {
                    return (
                      <li
                        key={item.url}
                        css={{
                          borderBottom: "1px solid #CACACA",
                          padding: "0.25rem",
                          ":first-of-type": {
                            paddingTop: "0.5rem"
                          },
                          ":last-of-type": {
                            borderBottom: "none"
                          }
                        }}
                      >
                        <small>
                          <a
                            href={item.url}
                            css={{
                              color: colors.light,
                              ":hover, :active": {
                                color: colors.primary
                              }
                            }}
                          >
                            {item.title}
                          </a>
                        </small>
                      </li>
                    );
                  })}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default TableOfContents;
